











  import {
    SnippetCallbacks,
    SnippetConfig,
    XOn,
    XProvide
  } from '@empathyco/x-components';
  import { QueryPreviewInfo } from '@empathyco/x-components/queries-preview';
  import { DeviceDetector } from '@empathyco/x-components/device';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Dictionary } from '@empathyco/x-utils';
  import { Component, Inject, Provide, Vue, Watch } from 'vue-property-decorator';
  import currencies from './i18n/currencies';
  import '@empathyco/x-components/design-system/deprecated-full-theme.css';
  import './design-system/tokens.scss';
  import './tailwind/index.css';

  @Component({
    components: {
      DeviceDetector,
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      UrlHandler,
	    MainModal: () => import('./components/custom-main-modal.vue').then(m => m.default)
    }
  })
  export default class Layer extends Vue {
    protected breakpoints: Dictionary<number> = {
      mobile: 640,
      desktop: Number.POSITIVE_INFINITY
    };
    protected isOpen = false;

	  @XOn(['UserOpenXProgrammatically', 'UserClickedOpenX'])
	  open(): void {
		  this.isOpen = true;
	  }

    protected excludedExtraParams: Array<keyof SnippetConfig> = [
      'callbacks',
      'productId',
      'uiLang',
      'consent',
      'documentDirection',
      'filters',
      'isSpa',
      'queriesPreview'
    ];

    @Inject('snippetConfig')
    protected snippetConfig!: SnippetConfig;

    protected get documentDirection(): string {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (this.snippetConfig.documentDirection ?? 'ltr')
      );
    }

    @Provide('currencyFormat')
    public get currencyFormat(): string {
      return currencies[this.snippetConfig.currency!];
    }

    @XProvide('queriesPreviewInfo')
    public get queriesPreviewInfo(): QueryPreviewInfo[] | undefined {
      return this.snippetConfig.queriesPreview;
    }

    @Watch('snippetConfig.uiLang')
    syncLang(uiLang: string): void {
      this.$setLocale(uiLang);
    }
  }
